import React from "react"
import styled, { keyframes } from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = ({ location }) => {

  return (
    <Layout path={location}>
      <SEO title="Contact" />
      <CenterWrapper>
        <FlickeringText>
          <h1>Contact me</h1>
          <a href="https://www.linkedin.com/in/donatoruydattoli/"><p>LINKEDIN</p></a>
          <p>dordattoli@gmail.com</p>
        </FlickeringText>
      </CenterWrapper>
      
    
    </Layout>
  )
}

const Flicker = keyframes`
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
      text-shadow:
          -0.2rem -0.2rem 1rem #fff,
          0.2rem 0.2rem 1rem #fff,
          0 0 2rem #f0f,
          0 0 4rem #f0f,
          0 0 6rem #f0f,
          0 0 8rem #f0f,
          0 0 10rem #f0f;
      
      box-shadow:
          0 0 .5rem #fff,
          inset 0 0 .5rem #fff,
          0 0 2rem #f0f,
          inset 0 0 2rem #f0f,
          0 0 4rem #f0f,
          inset 0 0 4rem #f0f;        
  }
  
  20%, 24%, 55% {        
      text-shadow: none;
      box-shadow: none;
  }    
`

const TextOnlyAnimation =  keyframes`
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
      text-shadow:
          -0.2rem -0.2rem 1rem #fff,
          0.2rem 0.2rem 1rem #fff,
          0 0 2rem #f0f,
          0 0 4rem #f0f,
          0 0 6rem #f0f,
          0 0 8rem #f0f,
          0 0 10rem #f0f;  
  }
  
  20%, 24%, 55% {        
      text-shadow: none;
  }    
`

const FlickeringText = styled.div`
  font-size: .8rem;
  font-weight: 200;
  font-style: italic;
  color: #f0f;
  padding: 4rem 6rem 5.5rem;
  border: 0.4rem solid #f0f;
  border-radius: 2rem;
  /* text-transform: uppercase; */
  animation: ${Flicker} 1.5s infinite alternate;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    margin: 5px auto;
  }

  @media only screen and (max-width: 500px) {
    border: none;
    padding: 0;

    animation: ${TextOnlyAnimation} 1.5s infinite alternate;
  }
`
const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: 800px) {
    align-items: start;
  } 
`

export default Contact
